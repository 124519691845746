import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import './Whychoose.css'; // Make sure to create the CSS file
import Card1 from "../images/card1.png";
import { Link } from 'react-router-dom';

const CyberSecurity = () => {
  const [scrollDirection, setScrollDirection] = useState('down');

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      if (scrollY > lastScrollY) {
        setScrollDirection('down');
      } else {
        setScrollDirection('up');
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, []);

  const options = { threshold: 0.1, triggerOnce: true };

  const { ref: ref1, inView: inView1 } = useInView(options);
  const { ref: ref2, inView: inView2 } = useInView(options);
  const { ref: ref3, inView: inView3 } = useInView(options);
  const { ref: ref4, inView: inView4 } = useInView(options);

  return (
    <div className="container">
      <h1 className="title">Why Choose <span>Us</span></h1>
      <p className="subtitle">At Espozone, we are committed to delivering top-notch IT maintenance and support with a focus on security and quality. Here’s why you should choose us:</p>
      <div className="features">
        <motion.div 
          className="feature-item" 
          ref={ref1} 
          initial={{ opacity: 0, y: scrollDirection === 'down' ? 100 : -100 }} 
          animate={{ opacity: inView1 ? 1 : 0, y: inView1 ? 0 : (scrollDirection === 'down' ? 100 : -100) }}
          transition={{ duration: 0.5 }}
        >
          <div className='why-icon'><i className="fa-solid fa-user-secret"></i></div>
          <h3>Security Services</h3>
          <p>We offer comprehensive security services to protect your IT infrastructure from threats and vulnerabilities, ensuring your systems are secure and resilient.</p>
          <a href="#">Learn More</a>
        </motion.div>
        <motion.div 
          className="feature-item" 
          ref={ref2} 
          initial={{ opacity: 0, y: scrollDirection === 'down' ? 100 : -100 }} 
          animate={{ opacity: inView2 ? 1 : 0, y: inView2 ? 0 : (scrollDirection === 'down' ? 100 : -100) }}
          transition={{ duration: 0.5 }}
        >
          <div className='why-icon'><i className="fa-solid fa-tachograph-digital"></i></div>
          <h3>Data Privacy</h3>
          <p>Your data's privacy is our top priority. We implement stringent measures to safeguard your sensitive information, ensuring compliance with all relevant regulations.</p>
          <a href="#">Learn More</a>
        </motion.div>
        <motion.div 
          className="feature-item" 
          ref={ref3} 
          initial={{ opacity: 0, y: scrollDirection === 'down' ? 100 : -100 }} 
          animate={{ opacity: inView3 ? 1 : 0, y: inView3 ? 0 : (scrollDirection === 'down' ? 100 : -100) }}
          transition={{ duration: 0.5 }}
        >
          <div className='why-icon'><i className="fa-solid fa-file-shield"></i></div>
          <h3>Industry Certified</h3>
          <p>Our team is industry certified, bringing recognized expertise and credibility to every project. You can trust that you’re working with qualified professionals dedicated to excellence.</p>
          <a href="#">Learn More</a>
        </motion.div>
      </div>
      <motion.div 
        className="advanced-cyber-security" 
        ref={ref4} 
        initial={{ opacity: 0, y: scrollDirection === 'down' ? 100 : -100 }} 
        animate={{ opacity: inView4 ? 1 : 0, y: inView4 ? 0 : (scrollDirection === 'down' ? 100 : -100) }}
        transition={{ duration: 0.5 }}
      >
        <img src={Card1} alt="Secure Payment" className="image" />
        <div className="text">
          <h2>Advanced <span>Cyber Security</span> Provider</h2>
          <p>ESPOZONE offers Enhanced cybersecurity solutions with a team of experienced cyber security professionals. They will provide tailored guidance and strategic recommendations to address your organization's today and future security challenges.
          <br /><br />With advanced cybersecurity solutions like Vulnerability Assessments, Penetration Testing, Security Information and Event Management (SIEM), and Multifactor Authentication (MFA), you can help keep your business secure.
          </p>
          <Link to="/contact" className="contact-button"><button>Contact Us</button></Link>
        </div>
      </motion.div>
    </div>
  );
}

export default CyberSecurity;
