import React from 'react'
import Header from '../../Banner/banner';
import WhyPeople from "../../Whychoose/Whychoose";
import Services from "../../services/services";

export default function SubHome() {
  return (
    <>
    <Header />
    <Services />
    <WhyPeople />
    </>
  )
}
