
import React from 'react'
import { Outlet } from 'react-router-dom';
import Navigation from "../Navbar/navbar";
import Footer from '../footer/footer';

export default function Home() {
  return (
    <>
    <Outlet />
    <Footer />
    </>
  )
}
