// src/components/Popup/Popup.js
import React, { useState } from 'react';
import './PopUp.css';

const Popup = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('message', message);

    fetch('https://espozone.com/php/sendmail.php', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.text())
    .then(result => {
      alert(result.data.message);
      // Handle success or error based on response from PHP
    })
    .catch(error => {
      alert('Error submitting form');
    });

    setName('');
    setEmail('');
    setMessage('');
    onClose(); // Close the popup after submission
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Seamless IT Support for Your&nbsp;Business</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={handleNameChange}
            required
          />
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <textarea
            placeholder="Enter your message"
            value={message}
            onChange={handleMessageChange}
          ></textarea>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Popup;
