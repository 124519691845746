// Navbar.js
// import { Link } from 'react-router-dom';
import { Link } from 'react-scroll';
import React, { useState } from 'react';
import './navbar.css'; // Import your CSS file for styling
import Subnav from './Subnav';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleMobileToggle = () => {
    setIsMobile(!isMobile);
  };

  return (
    <div className='navbar-main-cont'>
    <nav className="navbar">
      <div className="logo">ESPOZONE</div>
      <ul className={`nav-items ${isMobile ? 'mobile' : ''}`}>
        <li><a href="/">Home</a></li>
        <li className={`dropdown ${isMobile ? 'mobile-dropdown' : ''}`}>
        <a href="/">Services</a>
          <ul className="dropdown-content">
            <li><Link to="ManagedServices" spy={true} smooth={true} offset={-70} duration={500}>Managed Services</Link></li>
            <li><Link to="MaintenanceServices" spy={true} smooth={true} offset={-70} duration={500}>Maintenance Services</Link></li>
            <li><Link to="ITCablingServices" spy={true} smooth={true} offset={-70} duration={500}>IT Cabling Services</Link></li>
            <li><Link to="ProjectManagementServices" spy={true} smooth={true} offset={-70} duration={500}>Project Management & Consultancy Services</Link></li>
            <li><Link to="IoTServices" spy={true} smooth={true} offset={-70} duration={500}>Internet Of Things (IOT)</Link></li>
            <li><Link to="ProductsIncorporated" spy={true} smooth={true} offset={-70} duration={500}>Products Incorporated in&nbsp;Services</Link></li>
          </ul>
        </li>
        <li><Subnav /></li>
      </ul>
      <div className="mobile-menu" onClick={handleMobileToggle}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
    </div>
    
  );
};

export default Navbar;
