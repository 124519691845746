import React, { useEffect, useRef, useState } from 'react';
import './services.css';

const Services = () => {
    const serviceItemsRef = useRef([]);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            const isScrollingDown = currentScrollY > lastScrollY;
            setLastScrollY(currentScrollY);

            serviceItemsRef.current.forEach((item, index) => {
                if (item) {
                    const rect = item.getBoundingClientRect();
                    const isInViewport = rect.top <= window.innerHeight && rect.bottom >= 0;
                    if (isInViewport) {
                        if (isScrollingDown) {
                            item.classList.add('show-bottom-to-top');
                            item.classList.remove('show-top-to-bottom');
                        } else {
                            item.classList.add('show-top-to-bottom');
                            item.classList.remove('show-bottom-to-top');
                        }
                    }
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <div className='services-main-cont' id='Services'>
            <h1>Services</h1>
            <div className="row">
                {[
                    { id: "MaintenanceServices", icon: "fa-solid fa-screwdriver-wrench", title: "Maintenance Services", text: "IT maintenance is a set of actions to ensure that a computer system remains secure and operational, whether on personal computers or company servers." },
                    { id: "ManagedServices", icon: "fa-solid fa-list-check", title: "Managed Services", text: "Your IT support should be as flexible and as scalable as your business. We guarantee you the best bang for your buck in leveraging expert IT support and services that cater to your unique requirements. Our team can provide you with a complete all-around suite of IT support required by today’s winning enterprises in various industries." },
                    { id: "ProjectManagementServices", icon: "fa-solid fa-diagram-project", title: "Project Management & Consultancy Services", text: "Being and end-to-end solution provider, we work closely with various technology partners and distributors across the value chain to design, build and deliver innovative solutions that impact the fast paced IT industry. We offer industry standard services together with a group of professionally trained resources to comply and meet specific needs for different clients." },
                    { id: "ITCablingServices", icon: "fa-solid fa-sitemap", title: "IT Cabling Services", text: "EC Technologies is your resource for expert connection of all devices on your network. Our vast experience and proven expertise with network cable installation enable reliable, worry-free integration of all the devices your company needs to compete in today’s fast-paced business environment." },
                    { id: "IoTServices", icon: "fa-solid fa-hands-asl-interpreting", title: "Internet Of Things (IOT)", text: "IoT and integration go hand in hand. With IoT, companies are moving more towards cloud-based systems to bring about their digital transformation. This enables the two to come together rapidly. Since technology is changing daily, integration needs to move at an accelerated level and speed. Therefore, with integration and IoT, speedy fusion comes together instantly." },
                    { id: "ProductsIncorporated", icon: "fas fa-puzzle-piece", title: "Products Incorporated in Services", text: "In today's competitive market, companies are constantly looking for ways to differentiate their services and provide added value to their customers. One effective strategy is to incorporate products into service offerings. This approach not only enhances the overall customer experience but also creates opportunities for upselling and fostering long-term customer loyalty." }
                ].map((service, index) => (
                    <div
                        className="service-item padd-15"
                        id={service.id}
                        ref={el => serviceItemsRef.current[index] = el}
                        key={index}
                    >
                        <div className="service-item-inner">
                            <div className="icon">
                                <i className={`fa ${service.icon}`}></i>
                            </div>
                            <h4><span>{service.title}</span></h4>
                            <p>{service.text}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Services;
