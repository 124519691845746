import React, { useState } from 'react';
import './contact.css';
import axios from 'axios';
import Navigation from "../Navbar/navbar";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    contact: '',
    email: '',
    services: [],
    message: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        services: checked
          ? [...prevData.services, value]
          : prevData.services.filter((service) => service !== value),
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://espozone.com/php/contactus.php', formData, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => alert(response.data.message))
    .catch(error => alert('Error submitting form'));
};
  return (
    <div className="contact-us-main-container">
      <Navigation />
      <div className="contact-us-container">
        <h1>Contact Us</h1>
        <p>Email or contact us. We'd love to hear from you</p>
        <p><a href='tel:+918971249922' target='_blank'><i className="fas fa-phone"></i>&nbsp; +91 8971249922</a></p>
        <p><a href='mailto:sales@espozone.com' target='_blank'><i className="fas fa-envelope"></i>&nbsp; sales@espozone.com</a></p>
        <p><a href='mailto:support@espozone.com' target='_blank'><i className="fas fa-envelope"></i>&nbsp; support@espozone.com</a></p>
        <p><i className="fa-solid fa-clock"></i>&nbsp; Monday - Friday, 9am to 630pm</p>
        <p><a href='https://maps.app.goo.gl/KA7Wf3GiHAzvWiDC9' target='_blank'><i className="fas fa-map-marker-alt"></i>&nbsp; No 26, 4th Main Road, K R Nagar, Choodasandra, Bangalore, India-560099</a></p>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className='inputs-contact'>
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
        <input type="text" name="company" placeholder="Company Name" value={formData.company} onChange={handleChange} />
        <input type="tel" name="contact" placeholder="Contact Number" value={formData.contact} onChange={handleChange} />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
        </div>
        <div className="checkbox-group">
          <label>
            <input type="checkbox" name="services" value="Managed Services" onChange={handleChange} /> Managed Services
          </label>
          <label>
            <input type="checkbox" name="services" value="Maintenance Services" onChange={handleChange} /> Maintenance Services
          </label>
          <label>
            <input type="checkbox" name="services" value="IT Cabling Services" onChange={handleChange} /> IT Cabling Services
          </label>
          <label>
            <input type="checkbox" name="services" value="Project Management & Consultancy Services" onChange={handleChange} /> Project Management & Consultancy Services
          </label>
          <label>
            <input type="checkbox" name="services" value="Internet Of Things (IOT)" onChange={handleChange} /> Internet Of Things (IOT)
          </label>
          <label>
            <input type="checkbox" name="services" value="Products Incorporated in Services" onChange={handleChange} /> Products Incorporated in Services
          </label>
          
        </div>
        <div className='contact-bottom-sec'>
          <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
          <div className="recaptcha">
            <div className="g-recaptcha" data-sitekey="your_site_key"></div>
          </div>
          <div><button type="submit">Submit</button></div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
