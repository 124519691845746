import React from 'react';
import { Link } from 'react-scroll';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <h1>ESPOZONE</h1>
          <p>At Espozone, we pride ourselves on providing reliable, efficient, and cost-effective IT maintenance and support services. Trust us to be your partner in navigating the complexities of technology, so you can focus on what you do best.</p>
          <div className="footer-social">
            <a href="#"><i className="fab fa-linkedin"></i></a>
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h3>ABOUT</h3>
            <ul>
              <li><a href="#">Our Story</a></li>
              <li><a href="#">The Team</a></li>
            </ul>
            <h3 className='footer-contact'>CONTACT</h3>
            <ul>
              <li><a href='tel:+918971249922' target='_blank'><i className="fas fa-phone"></i>&nbsp; +91 8971249922</a></li>
              <li><a href='mailto:sales@espozone.com' target='_blank'><i className="fas fa-envelope"></i>&nbsp; sales@espozone.com</a></li>
              <li><a href='mailto:support@espozone.com' target='_blank'><i className="fas fa-envelope"></i>&nbsp; support@espozone.com</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>SERVICES</h3>
            <ul>
            <li><Link to="ManagedServices" spy={true} smooth={true} offset={-70} duration={500}>Managed Services</Link></li>
            <li><Link to="MaintenanceServices" spy={true} smooth={true} offset={-70} duration={500}>Maintenance Services</Link></li>
            <li><Link to="ITCablingServices" spy={true} smooth={true} offset={-70} duration={500}>IT Cabling Services</Link></li>
            <li><Link to="ProjectManagementServices" spy={true} smooth={true} offset={-70} duration={500}>Project Management & Consultancy Services</Link></li>
            <li><Link to="IoTServices" spy={true} smooth={true} offset={-70} duration={500}>Internet Of Things (IOT)</Link></li>
            <li><Link to="ProductsIncorporated" spy={true} smooth={true} offset={-70} duration={500}>Products Incorporated in&nbsp;Services</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>ADDRESS</h3>
            <ul>
              <li><a href='https://maps.app.goo.gl/KA7Wf3GiHAzvWiDC9' target='_blank'><i className="fas fa-map-marker-alt"></i>&nbsp; No 26, 4th Main Road, <br />K R Nagar, Choodasandra, Bangalore, India-560099</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <a href="#">Privacy Policy</a> | <a href="#">Sitemap</a>
        <p>Copyright &copy; 2023 ESPOZONE | Powered by ESPOZONE</p>
      </div>
    </footer>
  );
};

export default Footer;
