import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from "./components/Home/Home";
import ContactUs from './components/contact/contact';
import SubHome from './components/Home/Subhome/Subhome';
import Popup from './components/PopUp/PopUp';

function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className='App'>
    <Router>
    {/* <ScrollToTop> */}
    <Routes>
    <Route path="/" element={<Home />} >
      <Route index element={<SubHome />} />
      <Route path="/contact" element={<ContactUs />} />
    </Route>
    </Routes>
    {/* </ScrollToTop> */}
    </Router>
    {showPopup && <Popup onClose={handleClosePopup} />}
    </div>
  
  );
}

export default App;
