import React from 'react';
import './banner.css'; // Import your CSS file for styling
import Navbar from "../Navbar/navbar";
import BannerImage from "../images/banner1.png";

const Banner = () => {
  return (
    <div className='banner-main-cont'>
      <Navbar />
    <div className="banner-main-text-cont">
    <div className='banner-text-content'>
        <h1>
        Discover the Power of <span>ESPOZONE</span>
        </h1>
        <h4>
        Helping you solve complex IT problems.
        We give you round-the-clock access to subject matter experts, 
        IT solutions and robust technologies so you won’t have to handle them on your own.
        </h4>
        <button>Learn more</button>
        
    </div>
    <img src={BannerImage} />
    </div>
    
    </div>
  );
};

export default Banner;
